<template>
  <div>
    <PageTitle>New Scrim</PageTitle>

    <AppTabs lazy>
      <template #tabs-start>
        <BNavItem class="border border-info border-bottom-0 mr-1" to="/scrims"> Search </BNavItem>
      </template>
      <AppTab title="New Scrim">
        <AddScrimForm />
      </AppTab>
    </AppTabs>
  </div>
</template>

<script>
import PageTitle from '@/components/generic/PageTitle.vue'
import AppTab from '@/components/generic/Tab.vue'
import AppTabs from '@/components/generic/Tabs.vue'

import AddScrimForm from '../../components/scrim/ScrimUploadForm.vue'

export default {
  name: 'NewScrim',
  components: { AddScrimForm, AppTab, AppTabs, PageTitle },
}
</script>

<style scoped></style>
