<template>
  <div class="mt-2">
    <FormController v-if="canAccessScrimsV1" class="form" :submit="submit" :valid="isValid" data-form-type="other">
      <template #default>
        <div class="p-3 mt-3 aug-item">
          <h5>Available Quotas</h5>
          <li v-for="quota in quotas" :key="quota.id">{{ quota.name }}: {{ quota.value }}</li>
        </div>

        <div class="form--group">
          <label for="title">Match name</label>
          <input
            type="text"
            class="form-control"
            id="title"
            minlength="3"
            maxlength="256"
            required
            v-model="scrimTitle"
            placeholder="Pick a unique name - e.g. Augment's training team with coach as sub"
            data-form-type="other"
            data-lpignore="true"
            name="title-search"
          />
        </div>

        <div class="form--row">
          <div class="form--group">
            <label for="date">Match date</label>
            <input
              type="date"
              class="form-control"
              id="date"
              required
              v-model="createdDate"
              data-form-type="other"
              data-lpignore="true"
              name="date-search"
            />
          </div>
          <div class="form--group">
            <label for="time">Match time</label>
            <input
              type="time"
              class="form-control"
              id="time"
              required
              step="3600"
              v-model="createdTime"
              data-form-type="other"
              data-lpignore="true"
              name="time-search"
            />
          </div>
        </div>

        <div class="form--group">
          <label for="vod_url">Twitch/Youtube url</label>
          <input
            type="url"
            class="form-control"
            id="vod_url"
            title="The video must be at least in 1080p."
            v-b-tooltip.focus.noninteractive
            v-model.trim="form.vod_url"
            data-form-type="other"
            data-lpignore="true"
            name="vod-url-search"
          />
        </div>

        <div class="form--row">
          <div class="form--group">
            <label label-for="start_time">Vod&nbsp;start&nbsp;time (Format:&nbsp;hh:mm:ss)</label>
            <input
              type="text"
              id="start_time"
              v-model.trim="vodStartTime"
              class="form-control"
              :class="{
                'is-valid': !errors.vod_start_time,
                'is-invalid': errors.vod_start_time,
              }"
              required
              v-mask="'##:##:##'"
              title="Enter the time 5 seconds before the first round."
              v-b-tooltip.focus.noninteractive
              data-form-type="other"
              data-lpignore="true"
              name="start-time-search"
            />
          </div>

          <div class="form--group">
            <label for="end_time">Vod&nbsp;end&nbsp;time (Format:&nbsp;hh:mm:ss)</label>
            <input
              type="text"
              id="end_time"
              v-model.trim="vodEndTime"
              class="form-control"
              :class="{
                'is-valid': !errors.vod_end_time,
                'is-invalid': errors.vod_end_time,
              }"
              required
              v-mask="'##:##:##'"
              title="Enter the time once you see team ATK/DEF won/lost."
              v-b-tooltip.focus.noninteractive
              data-form-type="other"
              data-lpignore="true"
              name="end-time-search"
            />
          </div>
        </div>

        <hr />

        <div class="form--row">
          <legend class="text-center">Own team</legend>
          <legend class="text-center">Opponent team</legend>
        </div>

        <div class="form--row">
          <div v-for="(team, idx) in form.teams" :key="idx" class="form--group">
            <label :for="`team${idx}name`">Name</label>
            <input
              type="text"
              :id="`team${idx}name`"
              class="form-control"
              v-model="team.alias"
              data-form-type="other"
              data-lpignore="true"
              :name="`team-${idx}-name-search`"
            />
          </div>
        </div>

        <div class="form--row">
          <div v-for="(team, idx) in form.teams" :key="idx" class="form--group">
            <div class="form-check">
              <input
                type="checkbox"
                :id="`team${idx}winlastround`"
                class="form-check-input"
                v-model="team.winLastRound"
                data-form-type="other"
                data-lpignore="true"
                :name="`team-${idx}-win-last-round-search`"
              />
              <label
                class="form-check-label"
                :for="`team${idx}winlastround`"
                v-b-tooltip.hover.noninteractive
                title="Mark if this team won the LAST round of the scrim"
                >Win last round</label
              >
            </div>
          </div>
        </div>

        <div class="p-3 mt-3 aug-item">
          <h5>Requirements</h5>
          <li>Valorant must run on <b>1920x1080</b> resolution.</li>
          <li>Minimap must be on <b>1.1</b> size and <b>1.0</b> zoom.</li>
          <li>Minimap must be using <b>Red</b> enemy highlight.</li>
          <h5 class="mt-3">Recommendations</h5>
          <li>Minimap toggle off player <b>Enumeration</b> to improve player location data.</li>
          <li>Minimap toggle off player <b>Vision Cones</b> to improve walls data.</li>
          <li>Reduce background or CPU intensive activities for better recorder quality.</li>

          <div style="font-size: 12px; line-height: 13px" class="mt-3">
            Scrims are removed if it failed to collect data after 3 hours, the consumed quotas will be refunded;
            however, the refunded quota will always be added to "val_subscription_scrim_creations".
          </div>
        </div>

        <button type="submit" class="mt-3 btn btn-warning">Submit</button>
      </template>
    </FormController>
    <ErrorAlert v-else>Sorry, but you don't have permission to upload scrims.</ErrorAlert>
  </div>
</template>

<style scoped>
.aug-item {
  background-color: #20373c;
  margin-bottom: 12px;
}
</style>

<script>
import { parseISO, format, set as setDate } from 'date-fns'
import { mapGetters, mapMutations } from 'vuex'

import { getQuotas } from '@/api/billing.js'
import { createScrim, createScrimTeam, createScrimReplay, createTeamTemp } from '@/api/scrims'
import mixpanel from '@/mixpanel.js'
import { getVodUrlDetails } from '@/utils/getVodUrlDetails.js'

import reportException from '../../utils/reportException.js'
import FormController from '../controllers/FormController.vue'
import ErrorAlert from '../generic/ErrorAlert.vue'

export default {
  name: 'AddScrimForm',
  components: { ErrorAlert, FormController },
  data() {
    getQuotas().then(data => (this.quotas = data.quotas.filter(q => q.name.includes('scrim_creation'))))
    return {
      loading: false,
      localVodStartTime: '00:00:00',
      localVodEndTime: '01:30:00',
      error: null,
      errors: {
        vod_start_time: null,
        vod_end_time: 'required',
      },
      titleDirty: false,
      form: {
        title: '',
        map_id: null,
        vod_url: null,
        vod_start_time: 0,
        vod_end_time: null,
        created_millis: setDate(new Date(), { minutes: 0, seconds: 0, milliseconds: 0 }).getTime(),
        created: new Date(),
        teams: [
          {
            id: null,
            alias: this.$store.getters['scrims/lastTeam1Name'],
            winLastRound: false,
            isSelfTeam: true,
          },
          {
            id: null,
            alias: this.$store.getters['scrims/lastTeam2Name'],
            winLastRound: false,
            isSelfTeam: false,
          },
        ],
      },
      quotas: [],
    }
  },
  computed: {
    ...mapGetters({
      canAccessScrimsV1: 'auth/can_access_scrims_v1',
      getLastCompositionsForMap: 'scrims/getLastCompositionsForMap',
      getMapById: 'static/getMapById',
      maps: 'static/mapsList',
    }),
    createdDate: {
      get() {
        return format(this.form.created_millis, 'yyyy-MM-dd')
        // return new Date(this.form.created_millis).toISOString().split('T').shift()
      },
      set(value) {
        if (!value) return
        try {
          this.form.created_millis = new Date(`${value}T${this.createdTime}`).getTime()
          this.form.created = new Date(this.form.created_millis).toISOString()
        } catch (e) {
          reportException(e, { inputType: 'date', value })
        }
      },
    },
    createdTime: {
      get() {
        return format(this.form.created_millis, 'HH:mm:ss')
        // return new Date(this.form.created_millis).toISOString().split('T').pop().substring(0, 5)
      },
      set(value) {
        if (!value) return
        try {
          this.form.created_millis = new Date(`${this.createdDate}T${value}`).getTime()
          this.form.created = new Date(this.form.created_millis).toISOString()
        } catch (e) {
          reportException(e, { inputType: 'time', value })
        }
      },
    },
    isValid() {
      return !this.errors.vod_end_time && !this.errors.vod_start_time
    },
    mapId() {
      return this.form.map_id
    },
    scrimTitle: {
      get() {
        return this.form.title
      },
      set(value) {
        this.form.title = value
        this.titleDirty = !!value
      },
    },
    suggestedMatchTitle() {
      if (!this.titleDirty && this.form.teams[0].alias && this.form.teams[1].alias) {
        return `${this.form.teams[0].alias} vs ${this.form.teams[1].alias}${
          this.form.map_id ? ` - ${this.getMapById(this.form.map_id)?.name}` : ''
        }`
      } else {
        return this.form.title
      }
    },
    team1WinLastRound() {
      return this.form.teams[0].winLastRound
    },
    team2WinLastRound() {
      return this.form.teams[1].winLastRound
    },
    vodStartTime: {
      get() {
        return this.localVodStartTime
        // return this.form.vod_start_time != null
        //   ? new Date(this.form.vod_start_time * 1000).toISOString().split('T').pop().substring(0, 8)
        //   : null
      },
      set(value) {
        this.localVodStartTime = value
        try {
          this.form.vod_start_time = Math.round(parseISO(`1970-01-01T${value}Z`).getTime() / 1000)
          this.errors.vod_start_time = Number.isNaN(this.form.vod_start_time) ? 'nan' : !value ? 'required' : null
        } catch (e) {
          this.errors.vod_start_time = e
        }
      },
    },
    vodEndTime: {
      get() {
        return this.localVodEndTime
        // return this.form.vod_end_time != null
        //   ? new Date(this.form.vod_end_time * 1000).toISOString().split('T').pop().substring(0, 8)
        //   : null
      },
      set(value) {
        this.localVodEndTime = value
        try {
          this.form.vod_end_time = Math.round(parseISO(`1970-01-01T${value}Z`).getTime() / 1000)
          this.errors.vod_end_time = Number.isNaN(this.form.vod_end_time) ? 'nan' : !value ? 'required' : null
        } catch (e) {
          this.errors.vod_end_time = e
        }
      },
    },
  },
  watch: {
    suggestedMatchTitle: {
      immediate: true,
      handler(val) {
        this.form.title = val
      },
    },
    team1WinLastRound(val) {
      if (val) {
        this.form.teams[1].winLastRound = false
      }
    },
    team2WinLastRound(val) {
      if (val) {
        this.form.teams[0].winLastRound = false
      }
    },
  },
  methods: {
    ...mapMutations({
      setLastTeam1Name: 'scrims/setLastTeam1Name',
      setLastTeam2Name: 'scrims/setLastTeam2Name',
    }),
    async submit() {
      try {
        this.setLastTeam1Name(this.form.teams[0].alias)
        this.setLastTeam2Name(this.form.teams[1].alias)
      } catch (e) {
        reportException(e)
      }

      const scrim = await createScrim({
        name: this.form.title,
        map: this.form.map_id,
        created: this.form.created,
      })

      const createdTeams = await Promise.all(
        this.form.teams?.map(team =>
          createTeamTemp({
            name: team.alias,
          })
        )
      )

      await Promise.all(
        this.form.teams?.map((team, idx) =>
          createScrimTeam({
            scrimId: scrim.id,
            team: createdTeams[idx].id,
            winLastRound: team.winLastRound,
            isSelfTeam: team.isSelfTeam,
          })
        )
      )

      const vodUrlDetails = getVodUrlDetails(this.form.vod_url)

      await createScrimReplay({
        scrimId: scrim.id,
        videoPlatform: vodUrlDetails.vodPlatform,
        videoId: vodUrlDetails.vodId,
        videoStartTime: this.form.vod_start_time,
        videoEndTime: this.form.vod_end_time,
      })

      const { vod_url } = this.form

      mixpanel.track_scrim_upload({
        vod_url,
        start_time: this.form.vod_start_time,
        end_time: this.form.vod_end_time,
      })

      return scrim
    },
  },
}
</script>

<style lang="scss" scoped>
.form {
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  max-width: 40em;

  &--row {
    display: flex;
    justify-content: space-between;
    gap: 4em;
  }

  &--group {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    margin-bottom: 1rem;
    flex: 1 1 auto;
  }
}
</style>
