var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mt-2" },
    [
      _vm.canAccessScrimsV1
        ? _c("FormController", {
            staticClass: "form",
            attrs: {
              submit: _vm.submit,
              valid: _vm.isValid,
              "data-form-type": "other"
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function() {
                    return [
                      _c(
                        "div",
                        { staticClass: "p-3 mt-3 aug-item" },
                        [
                          _c("h5", [_vm._v("Available Quotas")]),
                          _vm._l(_vm.quotas, function(quota) {
                            return _c("li", { key: quota.id }, [
                              _vm._v(
                                _vm._s(quota.name) + ": " + _vm._s(quota.value)
                              )
                            ])
                          })
                        ],
                        2
                      ),
                      _c("div", { staticClass: "form--group" }, [
                        _c("label", { attrs: { for: "title" } }, [
                          _vm._v("Match name")
                        ]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.scrimTitle,
                              expression: "scrimTitle"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            id: "title",
                            minlength: "3",
                            maxlength: "256",
                            required: "",
                            placeholder:
                              "Pick a unique name - e.g. Augment's training team with coach as sub",
                            "data-form-type": "other",
                            "data-lpignore": "true",
                            name: "title-search"
                          },
                          domProps: { value: _vm.scrimTitle },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.scrimTitle = $event.target.value
                            }
                          }
                        })
                      ]),
                      _c("div", { staticClass: "form--row" }, [
                        _c("div", { staticClass: "form--group" }, [
                          _c("label", { attrs: { for: "date" } }, [
                            _vm._v("Match date")
                          ]),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.createdDate,
                                expression: "createdDate"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: {
                              type: "date",
                              id: "date",
                              required: "",
                              "data-form-type": "other",
                              "data-lpignore": "true",
                              name: "date-search"
                            },
                            domProps: { value: _vm.createdDate },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.createdDate = $event.target.value
                              }
                            }
                          })
                        ]),
                        _c("div", { staticClass: "form--group" }, [
                          _c("label", { attrs: { for: "time" } }, [
                            _vm._v("Match time")
                          ]),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.createdTime,
                                expression: "createdTime"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: {
                              type: "time",
                              id: "time",
                              required: "",
                              step: "3600",
                              "data-form-type": "other",
                              "data-lpignore": "true",
                              name: "time-search"
                            },
                            domProps: { value: _vm.createdTime },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.createdTime = $event.target.value
                              }
                            }
                          })
                        ])
                      ]),
                      _c("div", { staticClass: "form--group" }, [
                        _c("label", { attrs: { for: "vod_url" } }, [
                          _vm._v("Twitch/Youtube url")
                        ]),
                        _c("input", {
                          directives: [
                            {
                              name: "b-tooltip",
                              rawName: "v-b-tooltip.focus.noninteractive",
                              modifiers: { focus: true, noninteractive: true }
                            },
                            {
                              name: "model",
                              rawName: "v-model.trim",
                              value: _vm.form.vod_url,
                              expression: "form.vod_url",
                              modifiers: { trim: true }
                            }
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "url",
                            id: "vod_url",
                            title: "The video must be at least in 1080p.",
                            "data-form-type": "other",
                            "data-lpignore": "true",
                            name: "vod-url-search"
                          },
                          domProps: { value: _vm.form.vod_url },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.form,
                                "vod_url",
                                $event.target.value.trim()
                              )
                            },
                            blur: function($event) {
                              return _vm.$forceUpdate()
                            }
                          }
                        })
                      ]),
                      _c("div", { staticClass: "form--row" }, [
                        _c("div", { staticClass: "form--group" }, [
                          _c(
                            "label",
                            { attrs: { "label-for": "start_time" } },
                            [_vm._v("Vod start time (Format: hh:mm:ss)")]
                          ),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model.trim",
                                value: _vm.vodStartTime,
                                expression: "vodStartTime",
                                modifiers: { trim: true }
                              },
                              {
                                name: "mask",
                                rawName: "v-mask",
                                value: "##:##:##",
                                expression: "'##:##:##'"
                              },
                              {
                                name: "b-tooltip",
                                rawName: "v-b-tooltip.focus.noninteractive",
                                modifiers: { focus: true, noninteractive: true }
                              }
                            ],
                            staticClass: "form-control",
                            class: {
                              "is-valid": !_vm.errors.vod_start_time,
                              "is-invalid": _vm.errors.vod_start_time
                            },
                            attrs: {
                              type: "text",
                              id: "start_time",
                              required: "",
                              title:
                                "Enter the time 5 seconds before the first round.",
                              "data-form-type": "other",
                              "data-lpignore": "true",
                              name: "start-time-search"
                            },
                            domProps: { value: _vm.vodStartTime },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.vodStartTime = $event.target.value.trim()
                              },
                              blur: function($event) {
                                return _vm.$forceUpdate()
                              }
                            }
                          })
                        ]),
                        _c("div", { staticClass: "form--group" }, [
                          _c("label", { attrs: { for: "end_time" } }, [
                            _vm._v("Vod end time (Format: hh:mm:ss)")
                          ]),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model.trim",
                                value: _vm.vodEndTime,
                                expression: "vodEndTime",
                                modifiers: { trim: true }
                              },
                              {
                                name: "mask",
                                rawName: "v-mask",
                                value: "##:##:##",
                                expression: "'##:##:##'"
                              },
                              {
                                name: "b-tooltip",
                                rawName: "v-b-tooltip.focus.noninteractive",
                                modifiers: { focus: true, noninteractive: true }
                              }
                            ],
                            staticClass: "form-control",
                            class: {
                              "is-valid": !_vm.errors.vod_end_time,
                              "is-invalid": _vm.errors.vod_end_time
                            },
                            attrs: {
                              type: "text",
                              id: "end_time",
                              required: "",
                              title:
                                "Enter the time once you see team ATK/DEF won/lost.",
                              "data-form-type": "other",
                              "data-lpignore": "true",
                              name: "end-time-search"
                            },
                            domProps: { value: _vm.vodEndTime },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.vodEndTime = $event.target.value.trim()
                              },
                              blur: function($event) {
                                return _vm.$forceUpdate()
                              }
                            }
                          })
                        ])
                      ]),
                      _c("hr"),
                      _c("div", { staticClass: "form--row" }, [
                        _c("legend", { staticClass: "text-center" }, [
                          _vm._v("Own team")
                        ]),
                        _c("legend", { staticClass: "text-center" }, [
                          _vm._v("Opponent team")
                        ])
                      ]),
                      _c(
                        "div",
                        { staticClass: "form--row" },
                        _vm._l(_vm.form.teams, function(team, idx) {
                          return _c(
                            "div",
                            { key: idx, staticClass: "form--group" },
                            [
                              _c(
                                "label",
                                { attrs: { for: "team" + idx + "name" } },
                                [_vm._v("Name")]
                              ),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: team.alias,
                                    expression: "team.alias"
                                  }
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  type: "text",
                                  id: "team" + idx + "name",
                                  "data-form-type": "other",
                                  "data-lpignore": "true",
                                  name: "team-" + idx + "-name-search"
                                },
                                domProps: { value: team.alias },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(team, "alias", $event.target.value)
                                  }
                                }
                              })
                            ]
                          )
                        }),
                        0
                      ),
                      _c(
                        "div",
                        { staticClass: "form--row" },
                        _vm._l(_vm.form.teams, function(team, idx) {
                          return _c(
                            "div",
                            { key: idx, staticClass: "form--group" },
                            [
                              _c("div", { staticClass: "form-check" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: team.winLastRound,
                                      expression: "team.winLastRound"
                                    }
                                  ],
                                  staticClass: "form-check-input",
                                  attrs: {
                                    type: "checkbox",
                                    id: "team" + idx + "winlastround",
                                    "data-form-type": "other",
                                    "data-lpignore": "true",
                                    name:
                                      "team-" + idx + "-win-last-round-search"
                                  },
                                  domProps: {
                                    checked: Array.isArray(team.winLastRound)
                                      ? _vm._i(team.winLastRound, null) > -1
                                      : team.winLastRound
                                  },
                                  on: {
                                    change: function($event) {
                                      var $$a = team.winLastRound,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              team,
                                              "winLastRound",
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              team,
                                              "winLastRound",
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(team, "winLastRound", $$c)
                                      }
                                    }
                                  }
                                }),
                                _c(
                                  "label",
                                  {
                                    directives: [
                                      {
                                        name: "b-tooltip",
                                        rawName:
                                          "v-b-tooltip.hover.noninteractive",
                                        modifiers: {
                                          hover: true,
                                          noninteractive: true
                                        }
                                      }
                                    ],
                                    staticClass: "form-check-label",
                                    attrs: {
                                      for: "team" + idx + "winlastround",
                                      title:
                                        "Mark if this team won the LAST round of the scrim"
                                    }
                                  },
                                  [_vm._v("Win last round")]
                                )
                              ])
                            ]
                          )
                        }),
                        0
                      ),
                      _c("div", { staticClass: "p-3 mt-3 aug-item" }, [
                        _c("h5", [_vm._v("Requirements")]),
                        _c("li", [
                          _vm._v("Valorant must run on "),
                          _c("b", [_vm._v("1920x1080")]),
                          _vm._v(" resolution.")
                        ]),
                        _c("li", [
                          _vm._v("Minimap must be on "),
                          _c("b", [_vm._v("1.1")]),
                          _vm._v(" size and "),
                          _c("b", [_vm._v("1.0")]),
                          _vm._v(" zoom.")
                        ]),
                        _c("li", [
                          _vm._v("Minimap must be using "),
                          _c("b", [_vm._v("Red")]),
                          _vm._v(" enemy highlight.")
                        ]),
                        _c("h5", { staticClass: "mt-3" }, [
                          _vm._v("Recommendations")
                        ]),
                        _c("li", [
                          _vm._v("Minimap toggle off player "),
                          _c("b", [_vm._v("Enumeration")]),
                          _vm._v(" to improve player location data.")
                        ]),
                        _c("li", [
                          _vm._v("Minimap toggle off player "),
                          _c("b", [_vm._v("Vision Cones")]),
                          _vm._v(" to improve walls data.")
                        ]),
                        _c("li", [
                          _vm._v(
                            "Reduce background or CPU intensive activities for better recorder quality."
                          )
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "mt-3",
                            staticStyle: {
                              "font-size": "12px",
                              "line-height": "13px"
                            }
                          },
                          [
                            _vm._v(
                              ' Scrims are removed if it failed to collect data after 3 hours, the consumed quotas will be refunded; however, the refunded quota will always be added to "val_subscription_scrim_creations". '
                            )
                          ]
                        )
                      ]),
                      _c(
                        "button",
                        {
                          staticClass: "mt-3 btn btn-warning",
                          attrs: { type: "submit" }
                        },
                        [_vm._v("Submit")]
                      )
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              false,
              1531710019
            )
          })
        : _c("ErrorAlert", [
            _vm._v("Sorry, but you don't have permission to upload scrims.")
          ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }