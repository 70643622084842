var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageTitle", [_vm._v("New Scrim")]),
      _c(
        "AppTabs",
        {
          attrs: { lazy: "" },
          scopedSlots: _vm._u([
            {
              key: "tabs-start",
              fn: function() {
                return [
                  _c(
                    "BNavItem",
                    {
                      staticClass: "border border-info border-bottom-0 mr-1",
                      attrs: { to: "/scrims" }
                    },
                    [_vm._v(" Search ")]
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c(
            "AppTab",
            { attrs: { title: "New Scrim" } },
            [_c("AddScrimForm")],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }