import axios from '@/axios.js'

export async function getPrices(limit = 50, offset = 0, orderBy = 'amount', { cancelToken } = {}) {
  const { data } = await axios.get('/users/v2/prices', {
    params: { limit, offset, order_by: orderBy },
    cancelToken,
  })
  return data || {}
}

export async function checkout({ priceId, successUrl, cancelUrl, applyTrial }) {
  const { data } = await axios.post(`/users/v2/prices/${priceId}/checkout`, {
    success_url: successUrl,
    cancel_url: cancelUrl,
    apply_trial: applyTrial || null,
  })
  return data || {}
}

export async function getQuotas(limit = 50, offset = 0, orderBy = 'name', { cancelToken } = {}) {
  const { data } = await axios.get('/users/v2/quotas', {
    params: { limit, offset, order_by: orderBy },
    cancelToken,
  })
  return data || {}
}

export async function getSubscriptions(limit = 50, offset = 0, orderBy, { cancelToken } = {}) {
  const { data } = await axios.get('/users/v2/subscriptions', {
    params: { limit, offset, order_by: orderBy },
    cancelToken,
  })
  return data || {}
}

export async function updateSubscription(subscriptionId, price) {
  const { data } = await axios.patch(`/users/v2/subscriptions/${subscriptionId}`, {
    ...(price ? { price: price.id } : { cancel_at_period_end: true }),
  })
  return data || {}
}

export async function getProducts(limit = 50, offset = 0, orderBy = '-created', { cancelToken } = {}) {
  const { data } = await axios.get('/users/v2/products', {
    params: { limit, offset, order_by: orderBy },
    cancelToken,
  })
  return data || {}
}
